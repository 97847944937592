import axios from "axios";

const API_BASE = `https://us-east4-subscribe-gtc.cloudfunctions.net/api/`;

const instance = axios.create({
  baseURL: API_BASE,
});

instance.interceptors.request.use(
  async (config) => {
    config.headers.common.Pragma = "no-cache";
    config.headers["Access-Control-Allow-Origin"] = "*";
    return config;
  },
  (error) => Promise.reject(error)
);

instance.defaults.headers.common.Accept = "application/json";
instance.defaults.headers.get["Content-Type"] = "application/json";

export default instance;
