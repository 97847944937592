import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  root: {
    borderRadius: 3,
  },
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#5CFBA1',
    },
    secondary: {
      main: '#1264A3',
    },
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
});

export default theme;
