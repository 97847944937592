import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FacebookIcon from "@material-ui/icons/Facebook";
import { LinkedIn } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));
const Socials = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <a href={"https://www.facebook.com/Kriterion-1769254633130145/"}>
            <FacebookIcon color="secondary" fontSize="large" />
          </a>
        </Grid>
        <Grid item xs={6}>
          <a href={"https://www.linkedin.com/company/28670999/"}>
            <LinkedIn color="secondary" fontSize="large" />
          </a>
        </Grid>
      </Grid>
    </div>
  );
};
export default Socials;
