import React from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";

import LandingPage from "./LandingPage";
import theme from "./styles/theme";

function App() {
  return (
    <>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />

        <LandingPage />
      </MuiThemeProvider>
    </>
  );
}

export default App;
