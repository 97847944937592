import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import KriterionLogo from "./assets/logo.svg";
import SubscribeForm from "./components/SubscribeForm";
import Socials from "./components/socials";
const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://kriterion.ai/">
        Kriterion
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    paddingTop: "25vh",

    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
    height: "91vh",
    // backgroundImage: `url(${KriterionBackgroud})`,
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const LandingPage = () => {
  const classes = useStyles();

  return (
    <>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h2"
                color="textPrimary"
                gutterBottom
              >
                Welcome to
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <img src={KriterionLogo} alt="Kriterion Logo" />
            </Grid>
            <br />
            <br />
            <Grid item xs={12}>
              <SubscribeForm />
            </Grid>
            <Grid item xs={12}>
              <Socials />
            </Grid>
          </Grid>
        </div>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Copyright />
      </footer>
      {/* End footer */}
    </>
  );
};

export default LandingPage;
