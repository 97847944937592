import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import api from "../config/api";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(6),
  },
}));

const submitEmail = async (email) => {
  await api.get("subscribe", {
    params: {
      email,
    },
  });
};

const SubscribeForm = (props) => {
  const classes = useStyles();
  const [emailAddress, setEmailAddress] = useState("");
  const [tempEmailAddress, setTempEmailAddress] = useState("sdasd");
  const [canUpdate, setCanUpdate] = useState(false);

  useEffect(() => {
    if (/.+@.+\.[A-Za-z]+$/.test(tempEmailAddress)) {
      setCanUpdate(true);
    } else {
      setCanUpdate(false);
    }
  }, [tempEmailAddress]);

  useEffect(() => {
    if (emailAddress !== undefined) {
      if (emailAddress !== "") {
        // eslint-disable-next-line no-unused-vars
        submitEmail(emailAddress)
          .then(() => {
            setEmailAddress("");
            setTempEmailAddress("");
          })
          .catch(() => {
            console.log("Error");
          });
      }
    }
  }, [emailAddress]);

  return (
    <>
      <Grid
        className={classes.content}
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography
            variant="h5"
            align="center"
            color="textSecondary"
            paragraph
          >
            Enter your email below to subscribe to our quarterly newsletter
          </Typography>
        </Grid>
        {/* <form noValidate autoComplete="off"> */}
        <Grid item xs={12}>
          <TextField
            size="medium"
            autoComplete="off"
            id="standard-basic"
            label="Email Address"
            default={"yourname@email.tld"}
            onChange={(event, state) => {
              setTempEmailAddress(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={!canUpdate}
            size="large"
            onClick={() => {
              setEmailAddress(tempEmailAddress);
            }}
          >
            Submit
          </Button>
        </Grid>
        {/* </form> */}
      </Grid>
    </>
  );
};
export default SubscribeForm;
